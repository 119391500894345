import { BackendCrm, StorageKeys } from "@origin-digital/platform-enums";

import { getCustomerBackends } from "@origin-digital/auth-utils";
import {
  IGeolocation,
  IPropertyWithIds,
} from "@origin-digital/event-dispatcher";
import {
  auth0EnvConfigs,
  getCachedJwtEntry,
  IAuth0Config,
} from "@origin-digital/origin-auth";
import bannerConfig from "./configurations.json";
import { PageManifestCrisisBanner } from "./CrisisBanner";

export type crisisLocation = Array<BackendCrm | "UNAUTH">;

export interface crisisACF {
  crisisPosition: string;
  crisisBannerType: "warning" | "info";
  crisisLocation: string[];
  crisisMessage: string;
  includePaths?: string[];
  states?: string[];
}

export interface CrisisBannerContent {
  id: number;
  title: {
    rendered: string;
  };
  modified: string;
  acf: crisisACF;
}

// Get API endpoint from configuration.json
export const getContentApiEndpoint = () => {
  const { env } = window.oetal || {};
  const { name: envName = "local" } = env || {};
  const config = bannerConfig[envName];
  return config.wpContentApi;
};

//sort array by acf:crisisPosition
const sortCrisisBanners = (banners: CrisisBannerContent[]) =>
  banners &&
  banners.sort((a, b) => {
    const apos = a.acf.crisisPosition === "top" ? 0 : 1;
    const bpos = b.acf.crisisPosition === "top" ? 0 : 1;
    return apos === bpos ? 0 : apos < bpos ? -1 : 1;
  });

// if previewCrisisBanner is set in the URL then preview is true
export const isCrisisBannerPreview = () => {
  const urlParams = new URLSearchParams(window?.location?.search);
  return (
    urlParams.has("previewCrisisBanner") &&
    urlParams.get("previewCrisisBanner") !== "false"
  );
};

export const createBannerState = (
  status: BannerStatus,
  banners?: CrisisBannerContent[]
) => {
  return banners?.reduce((a, banner) => {
    const { id } = banner;
    return { ...a, [id]: { status } };
  }, {});
};

export const updateLocalStorage = (key, value) => {
  const sessionStr = localStorage.getItem(key);
  const sessionObj = sessionStr ? JSON.parse(sessionStr) : {};
  const newSessionObj = { ...sessionObj, ...value };
  localStorage.setItem(key, JSON.stringify(newSessionObj));
};

// Store banner JSON in local storage
const setCrisisBannerContentInLocalStorage = (
  content: CrisisBannerContent[]
) => {
  localStorage.setItem(StorageKey, JSON.stringify(content));
};

const loadContentFromLocalStorage = (key): CrisisBannerContent[] | null => {
  const localContentStr = localStorage.getItem(key);
  if (localContentStr === "undefined") {
    localStorage.removeItem(key);
    return null;
  }
  if (!localContentStr) {
    return null;
  }
  return JSON.parse(localContentStr);
};

// Fetch banner JSON from wordpress
const doFetch = async (url, requestOptions): Promise<any> => {
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(
      `${url} failed to fetch. Status code was ${response.status} `
    );
  }
  return response.headers.get("content-length") === "0"
    ? null
    : response.json();
};

const fetchContent = async (url) =>
  doFetch(url, {
    method: "GET",
    cache: "no-cache",
  });

export const getCrisisBanners = async () => {
  try {
    const response = await fetchContent(getContentApiEndpoint());
    sortCrisisBanners(response);
    return response;
  } catch (error) {
    console.error("Error fetching crisis banners:");
    console.error(error);
    return [];
  }
};

// deeply compare two values
const equals = (a, b) => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
    return a === b;

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};
interface IBannerState {
  status: BannerStatus;
}
export const StorageKey = StorageKeys.crisisBanner;
export const bannerState = "bannerState";
export type BannerStatus = "open" | "closed";

export const getBannersInState = (
  state: BannerStatus,
  banners: CrisisBannerContent[]
) => {
  const bannerStatus = localStorage.getItem(bannerState);
  if (bannerStatus) {
    const status: Record<string, IBannerState> = JSON.parse(bannerStatus);
    return banners.filter((banner) => {
      // Assume that banners that do not have a state are open,
      return (status[banner.id]?.status ?? "open") === state;
    });
  }
  // in case of no state, assume all banners are open
  return state === "open" ? banners : [];
};

export const loadContent = async (): Promise<CrisisBannerContent[]> => {
  const localContent = loadContentFromLocalStorage(StorageKey);
  const cmsContent = await getCrisisBanners();
  if (!localContent || !equals(localContent, cmsContent)) {
    const closedBannerIds = getBannersInState("closed", cmsContent).map(
      (closedBanner) => closedBanner.id
    );
    const newBanners = cmsContent.filter(
      (banner) => !closedBannerIds.includes(banner.id)
    );
    const bannerStateObject = createBannerState("open", newBanners);
    updateLocalStorage(bannerState, bannerStateObject);
    setCrisisBannerContentInLocalStorage(cmsContent);
    return cmsContent;
  }

  return localContent;
};

export const hasMatchingBackend = (
  crisisLocation: string[],
  backends: string[]
) => crisisLocation.some((e) => backends.includes(e));

export const getCachedJwtBackends = (auth0Config: IAuth0Config): any => {
  const cachedJwtEntry = getCachedJwtEntry(auth0Config);
  if (cachedJwtEntry) {
    const accessToken = cachedJwtEntry.body.access_token;
    return getCustomerBackends(accessToken);
  }
  return [];
};

export const checkIncludedPaths = (
  banners: CrisisBannerContent[],
  position: PageManifestCrisisBanner
) =>
  banners.filter((item) => {
    const includePaths = item.acf.includePaths;
    if (!includePaths || includePaths.length === 0) return position !== "none";

    return includePaths.some((path) => {
      const currentPath = window.location.pathname.toLowerCase();
      // Special case for homepage
      if (path === "/") {
        return currentPath === "/";
      }
      return currentPath.startsWith(path.toLowerCase());
    });
  });

export const checkGeoLocation = (
  banners: CrisisBannerContent[],
  allProperties: IPropertyWithIds[],
  userLocation: IGeolocation
) => {
  // Early return if no location data and no properties
  if (!userLocation?.state && allProperties.length === 0) {
    return banners;
  }

  // Pre-process user and property states to lowercase for case-insensitive comparison
  const userStates = new Set<string>();
  if (userLocation?.state) {
    userStates.add(userLocation.state.toLowerCase());
  }

  // Add property states to the set
  allProperties.forEach((property) => {
    const state = property.address?.state?.toLowerCase();
    if (state) {
      userStates.add(state);
    }
  });

  // If no states to check against, return all banners
  if (userStates.size === 0) {
    return banners;
  }

  return banners.filter((item) => {
    const states = item.acf.states;
    // If no states specified, show the banner
    if (!states?.length) {
      return true;
    }

    // Check if any of the user's states match the banner's states
    return states.some((state) => userStates.has(state.toLowerCase()));
  });
};

export const checkBackends = (banners: CrisisBannerContent[]) => {
  // grab the cached JWT backends.  This saves a fair bit of time over fetchPlatformParameters() as the
  // auth0 SDK is not involved and does not have to initialise and make back end calls.
  // Additionally, the auth0 SDK seems to have issues in func tests.  If the user has already
  // authenticated then the JWT will be cached.  So, pretty simple even if not perfect (like the JWT
  // could have expired), but, this is not about securing anything, just checking what messages
  // to show.
  const jwtBackEnds = getCachedJwtBackends(
    auth0EnvConfigs[window.oetal.env.name]
  );
  const backEnds = jwtBackEnds && jwtBackEnds.length ? jwtBackEnds : ["UNAUTH"];

  return banners.filter(
    (item) => hasMatchingBackend(item.acf.crisisLocation, backEnds) && item.id
  );
};

export const processDisplayLogic = (
  rawContent: CrisisBannerContent[],
  allProperties: IPropertyWithIds[] = [],
  userLocation: IGeolocation = {},
  position: PageManifestCrisisBanner
) => {
  // check is preview
  const isPreview = isCrisisBannerPreview();
  if (isPreview) return rawContent;

  // check localStorage for open banners
  let openBanners = getBannersInState("open", rawContent);
  if (openBanners.length === 0) return [];

  // Check includePaths
  openBanners = checkIncludedPaths(openBanners, position);
  if (openBanners.length === 0) return [];

  // Check geo location
  openBanners = checkGeoLocation(openBanners, allProperties, userLocation);
  if (openBanners.length === 0) return [];

  // Check backends
  openBanners = checkBackends(openBanners);
  if (openBanners.length === 0) return [];

  // return first 2 banners
  return openBanners.slice(0, 2);
};
