import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  eventDispatcher,
  eventListener,
  fetchGeolocation,
  Multicast,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { useUserContext } from "@origin-digital/user-context-provider";
import styled from "styled-components";
import { getAllPropertiesForUser } from "../PropertySelector/utils";
import { BannerView } from "./BannerView";
import {
  bannerState,
  createBannerState,
  CrisisBannerContent,
  loadContent,
  processDisplayLogic,
  updateLocalStorage,
} from "./helpers";

const Container = styled.div`
  position: relative;
`;

export type PageManifestCrisisBanner = "float" | "top" | "none";

const Wrapper = styled.div<{ position: PageManifestCrisisBanner }>`
  position: ${(props) => (props.position === "float" ? "absolute" : "flex")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: white;
`;

export const CrisisBanner = ({
  position = "top",
}: {
  position: PageManifestCrisisBanner;
}) => {
  const [showBanner, setShowBanner] = useState(true);
  const [bannerContent, setBannerContent] = useState<
    CrisisBannerContent[] | undefined
  >(undefined);

  const onCloseButtonClick = () => {
    eventDispatcher.dispatch({
      topic: TOPICS.CRISIS_BANNER_CLOSE,
      payload: {},
      multicast: Multicast.ALL_TABS,
    });
    //Only close displayed banners, in (officially unsupported) case that there are open banners that aren't displayed
    const bannerStatus = createBannerState("closed", bannerContent);
    updateLocalStorage(bannerState, bannerStatus);
    setShowBanner(false);
  };

  useEffect(() => {
    return eventListener.addMulticastListener(
      TOPICS.CRISIS_BANNER_CLOSE,
      () => {
        setShowBanner(false);
      }
    );
  }, [setShowBanner]);

  const { user } = useUserContext();
  const allProperties = useMemo(() => getAllPropertiesForUser(user), [user]);

  const loadBanner = useCallback(async () => {
    const [rawContent, userLocation] = await Promise.all([
      loadContent(),
      fetchGeolocation({}),
    ]);
    const [activeProperties, inactiveProperties] = allProperties;

    const bannersToDisplay = processDisplayLogic(
      rawContent,
      [...activeProperties, ...inactiveProperties],
      userLocation,
      position
    );

    if (bannersToDisplay.length === 0) return;

    setShowBanner(true);
    setBannerContent(bannersToDisplay);
  }, [allProperties, position]);

  useEffect(() => {
    try {
      loadBanner();
    } catch (error: unknown) {
      console.error("CrisisBanner Error:", {
        message: (error as Error)?.message || "Unknown Error",
      });
    }
  }, [loadBanner]);

  return showBanner && bannerContent ? (
    <Container>
      <Wrapper position={position}>
        {bannerContent.map((item, index) => {
          return (
            <BannerView
              key={index}
              index={index}
              bannerId={item.id}
              type={item.acf.crisisBannerType}
              heading={item.title.rendered}
              message={item.acf.crisisMessage}
              onCloseButtonClick={onCloseButtonClick}
              closeBtnDataId="cbCloseBtn"
            />
          );
        })}
      </Wrapper>
    </Container>
  ) : null;
};
